<script>
export default {
  name: "PauseIcon"
}
</script>

<template>

  <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" version="1.1"
       xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path
        d="M 320 42.66667 C 225.74293 42.66667 149.33333 119.07669 149.33333 213.33333 V 810.66667 C 149.33333 904.92373 225.74293 981.33333 320 981.33333 H 704 C 798.25707 981.33333 874.66667 904.92373 874.66667 810.66667 V 768 H 917.33333 C 952.68053 768 981.33333 739.34720 981.33333 704 V 640 C 981.33333 604.6528 952.68053 576 917.33333 576 H 874.66667 V 213.33333 C 874.66667 119.07669 798.25707 42.66667 704 42.66667 H 320 Z M 789.33333 576 H 426.66667 C 391.31947 576 362.66667 604.6528 362.66667 640 V 704 C 362.66667 739.34720 391.31947 768 426.66667 768 H 789.33333 V 810.66667 C 789.33333 857.79413 751.12747 896 704 896 H 320 C 272.87253 896 234.66667 857.79413 234.66667 810.66667 V 213.33333 C 234.66667 166.20501 272.87253 128 320 128 H 704 C 751.12747 128 789.33333 166.20501 789.33333 213.33333 V 576 Z M 362.66667 277.33333 C 362.66667 241.98613 391.31947 213.33333 426.66667 213.33333 H 597.33333 C 632.68053 213.33333 661.33333 241.98613 661.33333 277.33333 V 426.66667 C 661.33333 462.01387 632.68053 490.66667 597.33333 490.66667 H 426.66667 C 391.31947 490.66667 362.66667 462.01387 362.66667 426.66667 V 277.33333 Z M 448 298.66667 V 405.33333 H 576 V 298.66667 H 448 Z M 448 682.66667 V 661.33333 H 896 V 682.66667 H 448 Z"
        fill-opacity="0.85"></path>
  </svg>

</template>

<style scoped>

</style>