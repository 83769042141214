<template>

  <div style="display: flex; text-align: center; ">
    <div style="display: flex; align-items: center">
      <img v-if="code == 'motor'" style="width: 40px;height: 40px;" :src= "motorIcon === 'mid' ? midIcon:inwheelIcon "  alt="">
      <img v-else style="width: 40px;height: 40px;" src="@/assets/dashboard.webp" alt="">
      <div style="font-size: 22px;margin-left: 10px; margin-right: 10px;">{{ $t(name) }}</div>
      <el-tag size="large" :type="connected ? 'success' : 'info'">{{
        connected ? $t('ProductUpgrade.online') :
          $t('ProductUpgrade.offline')
      }}
      </el-tag>
    </div>

    <div style="margin: 5px 20px;" v-for="item, key in infos" :key="key">
      <div style="color: gray;">{{ $t(item.name) }}</div>
      <div style="font-size: 0.8em;color: gray;margin-top: 5px;">{{ item.value || '-' }}</div>
    </div>
    <div v-loading="loading" style="display: flex;align-items: center">

      <el-button @click="connect" link type="success">
        <el-icon>
          <Switch />
        </el-icon>
        {{ $t('ProductUpgrade.reconnecting') }}
      </el-button>
    </div>
  </div>
  <div style="height: 60vh; margin: 10px; border: 1px solid #e2e2e2;text-align: left;">

    <div v-if="connected">

      <div v-for=" item, key  in  uploadFiles " :key="key">
        <div v-if="item.visible" style="display: flex;flex-direction: row; margin: 20px; align-items: center">
          <div style="margin-right: 10px;">{{ $t(item.name) }}</div>
          <el-select v-model="item.fileName" :clearable="true" style="width: 340px" @clear="clearSelectFile(code,key)">
            <el-option v-for=" option, optionKey  in  item.options " :key="optionKey" :value="option.fileName">{{
        option.fileName }}
            </el-option>
          </el-select>
          <el-upload :action="uploadUrl" :headers="uploadHeader" :on-success="res => uploadSuccess(res, code, key)"
            :show-file-list="false" style="display: flex;align-items: center;">
            <el-button class="button-background-color" style="margin-left: 20px;">{{
        $t('ProductUpgrade.uploadLocalFile')
      }}
            </el-button>
          </el-upload>
        </div>
      </div>
      <el-button class="button-background-color" @click="() => { upgrade(code) }" :disabled="btnUpgrade"
        style="margin-top: 20px; margin-left: 20px;">{{ $t('ProductUpgrade.upgrade') }}
      </el-button>

    </div>
    <div v-else>
      <div style="display: flex; width: 100%;height: 100%; text-align: center;justify-content: center;">
        <img src="@/assets/noDeviceConnect.webp" alt="" width="570" height="376">
      </div>
      <div style="text-align: center;color: gray">{{ $t(name) }} {{ $t('ProductUpgrade.noConnection') }}</div>
    </div>
  </div>
  <el-button @click="check" v-if="connected" class="custom-reset-button">{{ $t('ProductUpgrade.check') }}</el-button>
  <el-dialog :model-value="dialogVisible" :title="isRestarting ? $t('ProductUpgrade.restarting') : $t('ProductUpgrade.upgrading') " align-center
    :close-on-click-modal="false" :show-close="false">
    <div v-if="uploadFiles?.config?.fileName && code=='motor'">
      <div>{{ $t('ProductUpgrade.parameterFileUpgrade') }}</div>
      <el-progress :percentage="configPercentage"></el-progress>
    </div>

    <div v-if="uploadFiles?.firmware?.fileName">
      <div>{{ $t('ProductUpgrade.firmwareUpgrade') }}</div>
      <el-progress :percentage="percentage"></el-progress>
    </div>

    <div v-if="uploadFiles?.config?.fileName && code=='display'">
      <div>{{ $t('ProductUpgrade.imageFileUpgrade') }}</div>
      <el-progress :percentage="configPercentage"></el-progress>
    </div>

    <template #footer>
      <div class="dialog-footer">
        <el-popconfirm :title="$t('ProductUpgrade.confirmCancel')" @confirm="upgradeCancel">
          <template #reference>
            <el-button :disabled="isRestarting" :loading="cancelLoading">{{ $t('ProductUpgrade.cancel') }}</el-button>
          </template>
        </el-popconfirm>

      </div>
    </template>
  </el-dialog>
  <el-dialog :model-value="checkVisiable" width="400px" :show-close="false" >
    <h3>{{ $t('ProductUpgrade.newVersionAvailable') }} {{ firmwareFileText }} <span v-if="lastConfigFile">{{ $t('ProductUpgrade.parameterVersion') }} {{ configFileText }}</span>
      {{ $t('ProductUpgrade.updateIs') }}</h3>
    <template #footer>
      <el-button @click="checkVisiable = false" type="default">{{ $t('ProductUpgrade.cancel') }}</el-button>
      <el-button @click="checkAndUpgrade" type="primary">{{ $t('ProductUpgrade.upgrade') }}</el-button>
    </template>

  </el-dialog>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue';
import { Switch } from "@element-plus/icons-vue";

import i18n from "@/i18n";
import { ElMessage } from 'element-plus';

const t = i18n.global.t; // 将 i18n.global.t 方法赋值给变量 t

const props = defineProps({
  code: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: ''
  },

  connected: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  dialogVisible: {
    type: Boolean,
    default: false
  },

  percentage: {},
  configPercentage: {},
  infos: {
    type: Array,
    default() {
      return []
    }
  },
  uploadFiles: {
    type: Object,
    default() {
      return {}
    }
  },
  connect: {
    type: Function,
    required: true,
  },
  upgrade: {
    type: Function,
    required: true
  },
  uploadSuccess: {
    type: Function,
    required: true
  },
  upgradeCancel: {
    type: Function,
    required: true
  },
  cancelLoading: {
    type: Boolean,
    required: true
  },
  lastFirmwareFile: {
    type: String,
    required: true
  },
  lastConfigFile: {
    type: String,
    required: true
  },
  checkUpgrade: {
    type: Function,
  },
  motorIcon:{
    type: String
  },
  clearSelectFile:{
    type:Function,
    require: true
  }
})

const midIcon = require('@/assets/image/mid-motor.png')
const inwheelIcon = require('@/assets/image/hub-motor.png')


const btnUpgrade = computed(() => {
  return !(props.connected && (props.uploadFiles.firmware?.fileName?.length > 0 || props.uploadFiles?.config?.fileName?.length > 0))
})
const firmwareFileText = computed(() => {
  var index = props.lastFirmwareFile.lastIndexOf("\\")

  return props.lastFirmwareFile.substring(index + 1, props.lastFirmwareFile.length)
})
const configFileText = computed(() => {
  var index = props.lastConfigFile.lastIndexOf("\\")

  return props.lastConfigFile.substring(index + 1, props.lastConfigFile.length)
})

const isRestarting = computed(() => {
  let firmwareEnable = props.uploadFiles?.firmware?.fileName.length > 0
  let configEnable = props.uploadFiles?.config?.fileName.length > 0
  let firmwareupload = props.percentage === 100
  let configUpload = props.configPercentage === 100
  if (firmwareEnable && !configEnable) return firmwareupload
  if (!firmwareEnable && configEnable) return configUpload
  if (firmwareEnable && configEnable) return firmwareupload && configUpload
  return false
})

const uploadUrl = process.env.VUE_APP_API_URL + '/api/common/upload'
const uploadHeader = { Authorization: 'Bearer ' + localStorage.getItem('token') }

const checkVisiable = ref(false)

function check() {
  checkVisiable.value = props.lastConfigFile != '' || props.lastFirmwareFile != ''
  if (!checkVisiable.value) ElMessage.info(t('ProductUpgrade.noNewVersion'))
}

function checkAndUpgrade() {
  checkVisiable.value = false
  props.checkUpgrade(props.lastFirmwareFile, props.lastConfigFile, props.code)
}

</script>