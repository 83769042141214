<script>
export default {
  name: "PauseIcon"
}
</script>

<template>

  <svg xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1024 1024" version="1.1"
       xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path
        d="M938.581333 208A170.666667 170.666667 0 0 0 768 42.666667H256l-5.333333 0.085333A170.666667 170.666667 0 0 0 85.333333 213.333333v597.333334l0.085334 5.333333A170.666667 170.666667 0 0 0 256 981.333333h149.333333a21.333333 21.333333 0 0 0 21.333334-21.333333v-42.666667a21.333333 21.333333 0 0 0-21.333334-21.333333h-149.333333l-4.266667-0.106667A85.333333 85.333333 0 0 1 170.666667 810.666667V213.333333l0.106666-4.266666A85.333333 85.333333 0 0 1 256 128h512l4.266667 0.106667A85.333333 85.333333 0 0 1 853.333333 213.333333v224a21.333333 21.333333 0 0 0 21.333334 21.333334h42.666666a21.333333 21.333333 0 0 0 21.333334-21.333334V213.333333l-0.085334-5.333333zM234.666667 266.666667a10.666667 10.666667 0 0 1 10.666666-10.666667h384a10.666667 10.666667 0 0 1 10.666667 10.666667v64a10.666667 10.666667 0 0 1-10.666667 10.666666h-384a10.666667 10.666667 0 0 1-10.666666-10.666666v-64z m0 192a10.666667 10.666667 0 0 1 10.666666-10.666667h234.666667a10.666667 10.666667 0 0 1 10.666667 10.666667v64a10.666667 10.666667 0 0 1-10.666667 10.666666h-234.666667a10.666667 10.666667 0 0 1-10.666666-10.666666v-64z m352 128a138.666667 138.666667 0 1 1 277.333333 0 138.666667 138.666667 0 0 1-277.333333 0z m192 0a53.333333 53.333333 0 1 0-106.666667 0 53.333333 53.333333 0 0 0 106.666667 0z m181.248 325.290666C957.312 821.098667 888.682667 746.666667 802.133333 746.666667h-153.6l-5.077333 0.085333C557.653333 749.653333 490.666667 825.813333 490.666667 917.333333v21.333334a21.333333 21.333333 0 0 0 21.333333 21.333333h42.666667a21.333333 21.333333 0 0 0 21.333333-21.333333v-21.333334l0.106667-4.608C578.176 866.901333 610.773333 832 648.533333 832h153.6l3.754667 0.106667C843.264 834.410667 874.666667 870.826667 874.666667 917.333333v21.333334a21.333333 21.333333 0 0 0 21.333333 21.333333h42.666667a21.333333 21.333333 0 0 0 21.333333-21.333333v-21.333334l-0.085333-5.376z"
        fill-opacity="0.85"></path>
  </svg>

</template>

<style scoped>

</style>